.header {
  border-radius: 8px;
  background-color: #f7f8fc;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  .sort {
    display: flex;
    flex-direction: row;
    .sortItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 24px;
      cursor: pointer;
      svg {
        display: block;
      }
      span {
        margin-left: 4px;
        .up {
          transform: rotate(180deg) translateY(1px);
        }
        .down {
          transform: translateY(1px);
        }
      }
      &.active {
        color: var(--sys-color-Primary700);
      }
    }
  }
  .ad {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 8px 12px;
    background: rgba(234, 248, 236, 1);
    border-radius: 5px;
    svg {
      margin-right: 6px;
    }
    b {
      font-size: 16px;
      margin-left: 4px;
    }
  }
}
