.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  .viewMore {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .moreBtn {
      cursor: pointer;
      padding: 10px 10px 10px 16px;
      border-radius: 4px;
      border: 1px solid #e0e1e5;
      background-color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .svgExpand {
        cursor: pointer;
        color: inherit;
        margin: 0 4px;
        position: relative;
      }

      .svgExpand {
        transform: rotate(0deg);
      }
    }
  }
}
