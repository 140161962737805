.uk-pc-seckill {
  width: 100%;

  .header_background {
    width: 100%;
    height: 120px;
  }

  .tabBar_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .leftArrow {
      margin-right: 20px;
      cursor: pointer;
      border-radius: 50%;

      &:hover {
        background-color: #0000003D;
      }
    }

    .rightArrow {
      margin-left: 20px;
      transform: scale(-1);
      cursor: pointer;
      border-radius: 50%;

      &:hover {
        background-color: #0000003D;
      }
    }

    .tabBar_window {
      max-width: 1128px;
      overflow: auto;
      // padding-bottom: 4px;
      border-radius: 6px;
      margin: 20px auto;
      background-color: #F0F1F2;
    }

    /* WebKit滚动条样式 */
    .tabBar_window::-webkit-scrollbar {
      height: 0px;
    }

    .tabBar_window::-webkit-scrollbar-track {
      background: #fff;
    }

    .tabBar_window::-webkit-scrollbar-thumb {
      background-color: transparent;
      cursor: pointer;
      border-radius: 4px;
    }

    .tabBar_box {
      width: fit-content;
      display: flex;
      border-radius: 6px;
      gap: 8px;
      padding: 5px;
    }

    .tabBar_item {
      transition: all 0.5s ease;
      border-radius: 6px;
      width: 180px;
      height: 100%;
      padding: 4px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      background: #F0F1F2;
      color: #888B94;

      .title {
        color: #1A1A1A;
      }

      .sep_wrap {
        font-size: 14px;
        font-weight: 400;
        color: #1A1A1A;
      }

      .EndsIn {
        font-size: 14px;
        font-weight: 400;
        color: #1A1A1A;
      }

      .outTime {
        color: #1A1A1A;

        span {
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          padding: 0 10px;
          background-color: #1A1A1A;
          color: #fff;
          border-radius: 4px;
        }
      }
    }

    .tabBar_item.isActive {
      background: #fff;

      .title {
        color: #FF0F23;
      }

      .EndsIn {
        color: #FF0F23;
      }

      .sep_wrap {
        color: #FF0F23;
      }

      .outTime {
        color: #FF0F23;

        span {
          background-color: #FF0F23;
        }
      }
    }

    .tabBar_item:active {
      background-color: #f0f1f22d;
    }



  }

  .content {
    display: grid;
    place-items: center;

    .content_wrap {
      width: 1264px;
      display: flex;
      grid-gap: 16px;
      flex-wrap: wrap;

      .content_item {
        width: 240px;
        margin-top: 16px;
        position: relative;

        &:hover::after {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          content: '';
          position: absolute;
          border: 1px solid #990915;
          border-radius: 4px;
          pointer-events: none;
          z-index: 11;
        }

        .image_box {
          width: 100%;
          height: 240px;
          background-color: #fff;
          position: relative;
          border-radius: 4px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 4px;
          }
        }
        .op50 {
          opacity: 0.5;
        }
        .UK_mask {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02));
    
        }

        .UK_outOfStock {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          z-index: 12;
          background: linear-gradient(0deg, rgba(225, 225, 225, 0.02), rgba(225, 225, 225, 0.02));
          display: flex;
          justify-content: center;
          align-items: center;

          .UK_outOfStock_text {
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #fff;
            text-align: center;
            padding: 5px 11px;
            background-color: #00000080;
            border-radius: 4px;
          }
        }


      }

      .content_item_main {
        width: 100%;
        margin: 8px 0 12px;
        padding: 0 12px;

        .item_main_title {
          width: 100%;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          vertical-align: middle;
        }

        .discount_rate_text {
          // width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .discount_rate_text:hover {
          color: #FF0F23;
        }

        .item_buy_seckill_btn {
          width: 100%;
          height: 40px;
          border-radius: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          position: relative;
          background: linear-gradient(90deg, #FFF6E8 0%, #FFF9EE 100%);
          margin-top: 8px;

          .item_main_amount_money_box {
            width: 60%;
            height: 28px;
            line-height: 28px;
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            overflow: hidden;
            margin: 6px;

            .originalPrice {
              display: flex;
              flex-wrap: wrap;
              overflow: hidden;
              height: 28px;
              line-height: 28px;
              align-items: center;
            }

            .off {
              display: inline-block;
              margin-left: 4px;
              color: #FF1C1C;
              font-weight: 500;
              font-size: 14px;
              line-height: 10px;
            }
          }

          .item_join_shop_btn {
            width: 66px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .isSeckill {
              width: 66px;

              .isOutOfStock {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                background-color: #C2C4CC;
                color: #fff;
                text-align: center;
                font-weight: 500;
                font-size: 14px;
                line-height: 40px;
              }

              .seckill_img {
                width: 60px;
                height: 40px;
                position: absolute;
                top: 0;
                right: 0;
              }
            }

            .unSeckill {
              width: 100%;
              height: 40px;
              border-radius: 8px;
              background-color: #00BC73;
              color: #fff;
              text-align: center;
              font-weight: 500;
              font-size: 14px;
              line-height: 40px;
            }
            .isReserved{
              opacity: 0.5;
            }
          }
        }

      }


      .progressContainer {
        margin-top: 8px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .sold {
          flex-shrink: 0;
          font-size: 12px;
          color: #888B94;
          margin-left: 10px;
        }

        .progressBar {
          flex: 1;
          width: 215px;
          border-radius: 5px;
          height: 5px;
          background-color: #FFEBED;

          .progressAnimate {
            position: relative;
            width: 80%;
            background-color: #FF0F23;
            border-radius: 5px;
            height: 6px;
          }
        }
        .progressBar.isOutOfStockBar{
          background-color: #C2C4CC         ;
        }
      }
    }
  }

  .view_more {
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 16px;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #E0E1E5;
    margin: 20px 0;
    cursor: pointer;
    user-select: none;
  }

  .view_more:active {
    background-color: #eee;
  }

  .loading {
    display: inline-block;
    padding: 10px;
    margin: 24px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #C2C4CC;

    .loadingIcon {
      display: inline-block;
      width: 24px;
      height: 24px;
      animation: spin 1s linear infinite;
      margin-right: 10px;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(-360deg);
      }
    }
  }

  .noMore {
    margin: 24px 0;
    font-size: 16px;
    color: #C2C4CC;
  }

  .errorBox {
    display: flex;
    justify-content: center;

    .emptyImg {
      width: 130px;
      height: 130px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 80px;
    }

    .emptyTxt {
      color: #666666;
      font-size: 14px;
      margin: 12px auto;
      text-align: center;
    }
  }


}