.container {
    // align-items: center;
    // justify-content: space-between;
    // justify-items: center;

    .top-select {
        height: 48px;
        display: flex;
        align-items: center;
        position: sticky;
        background: #FFFFFF;
        top: 0;
        z-index: 999;
        justify-content: space-between;

        .info-left{
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #1A1A1A;
        }
        .info-right{
            cursor: pointer;
            transition: color 0.3s ease;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #1A1A1A;
        }
        .info-right:hover {
            color: #990915;
        }
        .info-left:hover {
            color: #990915;
        }
    }

    .cart-item {
        display: flex;
        margin-left: 12px;
        margin-top: 16px;
        margin-bottom: 16px;
        // align-items: center;
        position: sticky;

        .img{
            width: 100px;
            height: 100px;
        }
        .img-container{
            position: relative;
            .img-top{
                opacity: 1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100px;
                background: #00000080;
                height: 28px;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 700;
                line-height: 28px;
                text-align: center;
                color: #FFFFFF;
            }
        }

        .text-container{
            margin-left: 16px;
            width: 360px;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .tag{
                height: 16px;
                margin-right: 4px;
                padding: 0px 2px 0px 2px;
                border-radius: 2px;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                text-align: center;
                color: white;
                background: linear-gradient(90deg, #FF475D 0%, #FF0F23 100%);
            }
            .name{
                font-size: 18px;
                font-weight: 400;
                // max-width: 360px;
                line-height: 24px;
                text-align: left;
                color: #1A1A1A;
                cursor: pointer;
                transition: color 0.3s ease;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }
            .name:hover {
                color: #990915;
            }
        }
        .text-container:hover {
            color: #990915;
        }

        .expanded {
            cursor: pointer;
            transition: color 0.3s ease;
            margin-top: 6px;
            margin-left: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #888B94;
        
            &:hover {
                color: #990915;
            }
        
            .svg-expand,
            .svg-expanded {
                color: #888B94;
                margin-left: 2px;
                cursor: pointer;
                color: inherit;
                top: -1px;
                position: relative;
                transition: transform 0.3s ease-in-out;
            }
        
            .svg-expand {
                transform: rotate(0deg);
            }
        
            .svg-expand:hover,
            .svg-expanded:hover {
                color: #990915;
            }
        
            .svg-expanded {
                transform: rotate(180deg);
            }
        }

        .popover-container{
            margin-top: 8px;
            width: 632px;
            height: 446px;
            padding: 16px;
            gap: 20px;
            border-radius: 4px;
            background: #FFFFFF;
            box-shadow: 0px 4px 28px 0px #0000000A;
            box-shadow: 0px 8px 24px 0px #0000000F;
        }

        .flash{
            margin-left: 6px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #CC6B0A;
            // .calculate{
            //     color: #CC6B0A;
            // }
        }

        .markInfoFloorParent {
            // display: flex;
            // position: absolute;
            flex-wrap: wrap; /* 如果需要换行 */
        }

        .tab-tag{
            margin-top: 6px;
            margin-left: 13px;
            height: 20px;
            margin-right: 4px;
            padding: 0px 4px 0px 4px;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            align-items: center;
            color: #269636;
            // background: #EAF8EC;
            display: flex;
            flex-shrink: 0;
            min-width: 0;
        }
    }

    .price-item {
        margin-left: 24px;
        min-width: 120px;
        .price{
            font-family: JDZhengHT-EN;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #1A1A1A;

            .big-price{
                font-family: JDZhengHT-EN;
                font-size: 24px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #1A1A1A;
            }
        }

        .origin-price{
            margin-top: 2px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #888B94;
        }

        .origin-price span {
            text-decoration: line-through;
        }

        .off {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            margin-left: 4px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #FF0F23;
        }
    }
    .counter{
        margin-left: 24px;
    }

    .more{
        right: -16px;
        top: -50px;
        position: absolute;
        .more-div{
            background: #1A1A1A;
        }
    }

    .button {
        display: flex;
        .left{
            width: 93px;
            height: 48px;
            padding: 10px 16px 10px 16px;
            gap: 4px;
            border-radius: 4px;
            // font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #FFFFFF;
            text-transform: capitalize;
            background: linear-gradient(90deg, #FF475D 0%, #FF0F23 100%);
        }
        .right{
            margin-left: 8px;
            width: 85px;
            height: 48px;
            padding: 10px 16px 10px 16px;
            gap: 4px;
            border-radius: 4px;
            color: #1A1A1A;
            border: 1px solid #E0E1E5;
            // font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            text-transform: capitalize;
            background: #fff;
        }
    }
}
.delete{
    cursor: pointer;
    transition: color 0.3s ease;
    border-radius: 4px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    color: #1A1A1A;
}
.delete:hover{
    color: #990915;
}