// 三级类目
.thirld-category-container {
  width: 1264px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 8px;
  padding-bottom: 16px;
  display: flex;

  .category-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .category-item {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #1A1A1A;
    border-radius: 4px;
    background-color: rgba(247, 248, 252, 1);
    margin: 8px 8px 0 0;
    cursor: pointer;
    box-shadow: inset 0 0 0 1px transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .category-selected {
    color: #CC0C1C;
    font-weight: 700;
    background-color: rgba(255, 245, 244, 1);
    box-shadow: inset 0 0 0 1px #CC0C1C;
  }

  .collapse {
    transform: rotate(0deg);
    transition: transform .5s ease;
  }

  .expand {
    transform: rotate(180deg);
    transition: transform .5s ease;
  }

  .expand-wrapper {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    align-self: flex-start;
    margin-top: 15px;
  }
}
