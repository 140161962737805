.downloadLink {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 20px 28px;
  border-bottom: 1px solid #0000000F;

  .appLink {
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: center;

    img {
      width: 142px;
      height: 56px;
    }
  }

  .qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 124px;
    height: 148px;
    background-color: #fff;
    border-radius: 4px;

    .qrImg {
      width: 100px;
      height: 100px;
      margin-top: 12px;
    }

    .qrText {
      margin-top: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--sys-Text-Tertiary);
    }
  }
}

.benefitPoint {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
  margin-left: 91px;
  .item {
    display: flex;
    align-items: center;
    .svg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      margin-right: 12px;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0%;
      color: var(--sys-Text-Primary);
    }
  }
}