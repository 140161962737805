.container {
    // display: flex;
    width: 1312px;
    height: 100%;
    // height: 680px;

    .free-shipping {
        height: 48px;
        // padding: 8px 12px 8px 12px;
        // background: linear-gradient(180deg, rgba(218, 255, 223, 0.5) 0%, #FFFFFF 100%);
        // gap: 6px;
        // border-radius: 12px;
        // background: #EAF8EC;
        top: 0;
        position: sticky;
        align-items: center;
        justify-content: space-between;
        padding-left: 24px;
        background-color: #fff;
        z-index: 999;
        display: flex;

        // border: 1px solid #ACE4B4;
        .price {
            font-size: 18px;
            font-weight: 600;
            line-height: 16px;
            text-align: left;
            color: #000000;
        }

        .info {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            text-align: left;
            color: #19191B;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .span {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            text-align: left;
            color: #269636;
        }
    }

    .tabs {
        padding-left: 20px;
        margin-bottom: 16px;

        .MuiTab-root {
            font-size: 14px;
            font-weight: 500;
            color: #19191B;
        }
    }


    .item-info {
        padding-left: 20px;
        text-align: left;
        margin-top: 8px;

        .item-title {
            font-size: 14px;
            font-weight: 500;
            color: #19191B;

            .tag {
                height: 16px;
                margin-right: 4px;
                padding: 0 2px;
                border-radius: 2px;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                text-align: center;
                color: white;
                background: linear-gradient(90deg, #FF475D 0%, #FF0F23 100%);
            }
        }

        .price-item {
            margin-top: 4px;
            margin-bottom: 4px;
            display: flex;
            align-items: baseline;

            .price {
                font-family: JDZhengHT-EN;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #1A1A1A;

                .big-price {
                    font-family: JDZhengHT-EN;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #1A1A1A;
                }
            }

            .origin-price {
                margin-left: 4px;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                color: #888B94;
            }

            .origin-price span {
                text-decoration: line-through;
            }
        }

        .item-rating {
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #1A1A1A;
        }

        .item-status {
            font-size: 12px;
            color: #FF4500; // 橙色
        }

        .bottom-cart {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .content {
        margin-left: 24px;
        margin-top: 16px;
        height: 36px;
        display: flex;
        gap: 12px;
        flex-wrap: wrap;

        .item {
            cursor: pointer;
            transition: color 0.3s ease;
            width: auto;
            display: flex;
            align-items: center;
            padding: 4px 12px;
            background-color: #F7F8FC;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #505259;

            &.active {
                border: 1px solid #FF6F7B;
                color: #CC0C1C;
                background-color: #FFF3F4;
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;
            }
        }

        .item:hover {
            cursor: pointer;
            color: #1A1A1A;
        }
    }

    .moreBtn {
        cursor: pointer;
        padding: 10px 16px 10px 16px;
        border-radius: 4px;
        border: 1px solid #E0E1E5;
        background-color: #fff;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 40px;

        .svgExpand {
            cursor: pointer;
            color: inherit;
            margin-left: 10px;
            top: -3px;
            position: relative;
            transition: transform 0.3s ease-in-out; // 添加过渡效果
        }

        .svgExpand {
            transform: rotate(0deg);
        }
    }

    .viewMore{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
}