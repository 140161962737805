.outTime {
  color: #1A1A1A;
  margin-left: 12px;
  span {
    display: inline-block;
    padding: 6px 8px;
    background-color: #1A1A1A;
    color: #fff;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 10px;
    text-align: center;
  }
}