.orderItemCardBox {
  background: #FFFFFF;
  color: #1A1A1A;
  font-size: 14px;
  line-height: 18px;
  padding: 16px;
  border-radius: 8px;
  margin: 16px 0;

  &:has(.viewAll){
    padding-bottom: 8px;
  }

  &+.orderItemCardBox {
    margin-top: 16px;
  }

  .title {
    display: flex;
    .promise{
      line-height: 24px;
      .promiseDate{
        margin-right: 8px;
        color: #269636;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .total{
      margin-left: auto;
      color: #888B94;
    }
  }

  .orderItemBox {
    padding-top: 16px;
    max-height: 236px;
    overflow: hidden;

    &.orderItemBoxExpand{
      max-height: none;
    }

    .productItem {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;


      +.productItem {
        margin-top: 20px;
      }

      .imageBox {
        width: 60px;
        height: 60px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.02);
        margin-right: 16px;
        flex-shrink: 0;
      }

      .basicInfo {
        font-size: 16px;
        line-height: 20px;
        color: #1A1A1A;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;


        .productName {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .discountTag {
            display: inline-block;
            font-size: 12px;
            line-height: 16px;
            color: #FFFFFF;
            border-radius: 2px;
            padding: 0 2px;
            margin-right: 6px;
            background: #CC0C1C;
            flex-shrink: 0;
          }

          .skuName {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .productSpec {
          color: #888B94;
          margin-top: 4px;
          cursor: default;
        }
      }

      .priceBox {
        margin-right: 40px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        font-family: JDZhengHT-EN;

        .skuPrice {
          margin-left: 4px;
        }
      }

      .quantityBox {
        width: 72px;
        flex-shrink: 0;
        .stockTag {
          height: 26px;
          line-height: 26px;
          background-color: #c2c4cc;
          border-radius: 4px;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          text-align: center;
        }
      }
    }

    .productItemNoStock{
      .imageBox{
        opacity: .5;
      }
      .skuName,
      .productSpec{
        color: #888B94 !important;
      }
    }
  }
  .viewAll {
    display: flex;
    justify-content: center;
    gap: 4px;
    height: 20px;
    font-size: 16px;
    cursor: pointer;
    &:hover{
      color: #FF0F23;
    }
  }

  .skuShippingPrice {
    color: #1A1A1A;
    font-size: 12px;
    line-height: 16px;

    .integer {
      font-size: 18px;
      line-height: 24px;
      margin-left: 2px;
    }
  }
}