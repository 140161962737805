.support_wrapper {
  background: #f5f6fa;
  padding: 32px 0;
  display: flex;
  justify-content: center;

  .main_wrapper {
    width: 1264px;
    display: flex;
    // margin: 0 auto;
  }

  .menu_wrapper {
    width: 19%;
    min-width: 240px;
    background: #FFF;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 4px 12px;
    align-self: flex-start;
  }

  .item_content {
    flex: 1;
    margin-left: 16px;
    background: #FFF;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 24px 36px;
    align-self: flex-start;

    .content_title {
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .versionIconWrap {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #1A1A1A;
        display: flex;
        align-items: center;
        cursor: pointer;
        
        span {
          margin-left: 6px;
        }
      }
    }

    .content_detail {
      margin-top: 24px;
    }
  }

  .empty-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-left: 16px;
    background: #FFF;
    border-radius: 8px;
  }
}
