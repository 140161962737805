.text {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--sys-Text-Secondary);
}
.nothing{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px;
}