.cardFormBox {
  width: 100%;
  padding-left: 32px;

  .cardFormItem {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    &__label {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
      color: #505259;
      display: flex;
    }
  }

  .timeCvvBox {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .cardFormItem:last-child {
      margin-left: 16px;
    }
  }

  .cardFormSelect {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .selectBox {
      display: flex;
      flex-direction: column;
      width: 100%;

      &:last-child {
        margin-left: 4px;
      }
    }

    .placeholder {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #C2C4CC;
    }
  }
}

.cvvDialogContent {
  padding: 0 48px 32px!important;
}