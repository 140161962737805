.container {
  margin-bottom: 24px;
}

.errorPage {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  padding-bottom: 30px;
}

.bannerWrap {
  background: #32250A;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  min-width: 1264px;
  position: relative;
}

.ranking {
  position: relative;
  padding: 24px 26px;
  margin-bottom: 24px;

  &::after {
    content: '';
    display: block;
    clear: both;
    background: rgba(0, 0, 0, 0.02);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    pointer-events: none;
    border-radius: 8px;
  }

  .rankingTitle {
    display: flex;
    color: #1a1a1a;
    margin-bottom: 22px;

    .rankingTitleText {
      flex: 1;
      font-size: 24px;
      line-height: 28px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .moreBtn {
    display: flex;
    align-items: center;
    font-size: 18px;
    gap: 5px;
    &:hover {
      color: #990915;
    }
  }

  .itemWrap {
    display: flex;
    justify-content: space-between;
  }
}

.rankingsWrap {
  width: 1264px;
  margin: 0 auto 24px;
}

.rankIcon {
  width: 36px;
  height: 45px;
  font-size: 24px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  color: #fff;
}

.rankIcon1 {
  background: url("../../../assets/icons/rank1.png") 0 0 no-repeat;
}

.rankIcon2 {
  background: url("../../../assets/icons/rank2.png") 0 0 no-repeat;
}

.rankIcon3 {
  background: url("../../../assets/icons/rank3.png") 0 0 no-repeat;
}

.rankIconMore {
  background: url("../../../assets/icons/rank4.png") 0 0 no-repeat;
}

.viewMoreBtnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .viewMoreBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 18px;
    font-weight: normal;
    padding: 12px 16px;
  }
}
