.sliderBox {
  display: flex;
  flex-grow: 1;
  position: relative;
  .currPrice {
    color: #1a1a1a;
    font-size: 14px;
    position: absolute;
    font-weight: 400;
    top: -20px;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
}
.btn {
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  margin-left: 16px;
  width: 31px;
  text-align: center;
  background-color: var(--sys-color-Primary700);
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
