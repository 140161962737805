.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  &-promotion {
    background-color: #fff3f4; // var(--sys-Surface-SurfaceRed)
    border: 0.5px solid var(--sys-State-Error300);
  }
  &-voucher {
    border: 0.5px solid var(--sys-State-Error300);
    background-color: #fff;
  }
  &-postFee {
    background-color: var(--sys-State-Success100);
    border: 0.5px solid var(--sys-State-Success300);
  }

  .promoRect {
    width: 4px;
    height: 90px;
    position: absolute;
    left: 0;
    background-color: var(--sys-State-Error300);
  }
  .promoInfo {
    display: flex;
    align-items: flex-end;
    .postFeeText {
      color: #269636 !important; // var(--sys-State-Success700)
      font-family: "JDZhengHT-EN";
    }
    .promotionCnt {
      font-weight: bold;
    }
    .rewardShow {
      color: var(--sys-color-Primary700);
      font-size: 28px;
      line-height: 28px;
      margin-right: 8px;
    }
    .promotionDetailText {
      color: var(--sys-color-Primary700);
      font-size: 18px;
      line-height: 24px;
      margin-right: 24px;
    }
    .endTime {
      color: var(--sys-Text-Tertiary);
      font-size: 18px;
      line-height: 24px;
    }
  }
  .search {
    overflow: hidden;
  }
}
