// 二级类目
.second-category-container {
  position: relative;
}

.second-category-container:hover {
  .pre-btn {
    opacity: 1;
  }

  .next-btn {
    opacity: 1;
  }

  .category-window::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.14);
  }

  .category-window::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.28);
    cursor: pointer;
    border-radius: 4px;
  }
}

.pre-btn {
  width: 20px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 42.8%;
  transform: translate(0, -50%);
  z-index: 2;
  background-color: rgba(199, 199, 199, 1);
  border-radius: 0 12px 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.5s ease;
}

.pre-btn:hover {
  background-color: rgba(136, 136, 136, 1);
}

.next-btn {
  width: 20px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 42.8%;
  transform: translate(0, -50%);
  z-index: 2;
  background: rgba(199, 199, 199, 1);
  border-radius: 12px 0 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s ease;
}

.next-btn:hover {
  background-color: rgba(136, 136, 136, 1);
}

.category-window {
  overflow: auto;
  padding-bottom: 4px;
}

/* WebKit滚动条样式 */
.category-window::-webkit-scrollbar {
  height: 4px;
}

.category-window::-webkit-scrollbar-track {
  background: #fff;
}

.category-window::-webkit-scrollbar-thumb {
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
}

.category-wrapper {
  width: fit-content;
  display: flex;
  padding: 0;
  margin: 0;
}

.category-item {
  transition: all 0.5s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: inset 0 0 0 1px transparent;
  margin-right: 22px;

  .category-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;
    height: 82px;
    width: 82px;
    background-color: #00000005;
  }

  .image-empty {
    height: 115px;
    background-color: rgba(0, 0, 0, 0.02);
  }

  .category-name {
    word-wrap: break-word;
    word-break: break-all;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;

    display: -webkit-box; /* 设置为弹性盒子，允许多行文本 */
    -webkit-box-orient: vertical; /* 设置盒子的排列方向为纵向 */
    -webkit-line-clamp: 2; /* 限制显示2行 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 超出部分用省略号表示 */
  }
}

.category-item:last-child {
  margin-right: 0;
}

.category-selected {
  box-shadow: inset 0 0 0 1px #cc0c1c;

  .category-name {
    font-weight: 700;
    color: #cc0c1c;
  }
}

.category-visible {
  opacity: 1;
}
