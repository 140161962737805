.safetyLoadingBox {
  position: fixed;
  top: calc(50vh - 52px);
  left: calc(50vw - 80px);
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #F0F1F2;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.08);
  color: #1A1A1A;
  background: #FFFFFF;
  z-index: 1000;
  display: flex;
  align-items: center;
  flex-direction: column;


  .imageBox {
    width: 60px;
    height: 60px;
    position: relative;

    .static {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 48px;
      height: 48px;
      background: url(https://st.joy-sourcing.com/website/other/safe.png) no-repeat center;
      background-size: 48px 48px;
    }

    .loading {
      width: 100%;
      height: 100%;
      background: url(https://st.joy-sourcing.com/website/other/safeLoading.png) no-repeat center;
      background-size: 60px 60px;
      font-size: 0;
      line-height: 0;
      animation: safetyLoading 1s infinite linear;
      position: relative;
    }

    @keyframes safetyLoading {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .safeTipsText {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .loadingGif {
    width: 100%;
    height: 100%;
    background: url(//st.joy-sourcing.com/website/other/loading.gif) no-repeat center;
    // background-size: 91px 17px;
    font-size: 0;
    line-height: 0;
    margin-top: 12px;
    height: 17px;
    width: 91px;
    transform: scale(0.5);
  }
}