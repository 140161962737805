.productList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 24px;
  .titleTips {
    font-weight: 400;
    display: inline-block;
    color: #fff;
    font-size: 12px;
    height: 16px;
    line-height: 16px;
    padding: 0px 2px;
    border-radius: 2px;
    background: linear-gradient(90deg, #ff475d 0%, #ff0f23 100%);
  }
}
