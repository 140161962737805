.detailWrapper {
  background: #f5f6fa;
  padding: 32px 0;
  display: flex;
  justify-content: center;

  .mainWrapper {
    width: 1264px;
    min-height: 792px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 24px 36px;
    background: #FFF;

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: #1A1A1A;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #1A1A1A;
      margin-top: 24px;
    }

    .versionWrapper {
      margin-top: 24px;

      .versionItem {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F0F1F2;
        padding: 16px 0;
        cursor: pointer;

        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: rgba(24, 105, 245, 1);
          margin-left: 6px;
        }
      }

      .versionItem:hover {
        background: #F7F8FC;

        a {
          text-decoration: underline;
        }
      }
    }
  }
}