.reservationModel {
  box-sizing: border-box;

  .nothing {
    height: 170px;
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
  }

  .reservatonProducts {
    .group {
      margin-bottom: 16px;

      .countdown {
        display: flex;
        align-items: center;

        .pinkDot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #FFD5D5;
          position: relative;

          &::after {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #FF1C1C;
            border-radius: 50%;
            position: absolute;
            top: 3px;
            left: 3px;
          }
        }

        .batchName {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          color: #000;
          margin-left: 7px;
        }
      }

      .product {
        display: flex;
        gap: 12px;
        height: 120px;
        margin-top: 10px;

        .left {
          width: 120px;
          height: 120px;
          border-radius: 4px;
          background: #00000005;
          overflow: hidden;
        }

        .right {
          display: flex;
          padding: 6px 0;
          flex-direction: column;
          justify-content: space-between;

          .skuName {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #1A1A1A;
          }
        }
      }
    }
  }
}