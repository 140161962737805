.limitBox {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  
  .limitH16 {
    margin-left: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #888b94;
    white-space: nowrap;
    transform-origin: 0 center;
  }

  .limitH100 {
    margin-left: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 10px;
    text-align: left;
    color: #888b94;
    transform-origin: 0 center;
    overflow: visible; /* 允许溢出 */
    word-break: break-all;
    white-space: normal; /* 多行显示 */
  }

  .expanded {
    cursor: pointer;
    margin-right: 12px;
    color: #888B94;
    transition: color 0.3s ease-in-out;

    &:hover {
        color: #FF3F4F;
    }

    .svgExpand,
    .svgExpanded {
        cursor: pointer;
        color: inherit;
        margin-left: 10px;
        top: -3px;
        position: relative;
        transition: transform 0.3s ease-in-out; // 添加过渡效果
    }

    .svgExpand {
        transform: rotate(0deg);
    }

    .svgExpand:hover,
    .svgExpanded:hover {
        color: #FF3F4F;
    }

    .svgExpanded {
        transform: rotate(180deg);
    }
  }
}