.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  user-select: none;
  z-index: 9999;
  background: #000000b2;

  .giftFirst {
    position: absolute;
    width: 375px;
    height: 520px;
    background: center no-repeat;
    background-size: 100% auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .close{
    // left: 50%;
    // top: 50%;
    cursor: pointer;
    margin-top: -540px;
    margin-left: 91%;
    position: absolute;
    // transform: translate(-50%, -50%);
    // padding-left: 94%;
    // padding-bottom: 130%;
  }
  .closeTop{
    cursor: pointer;
    margin-top: -590px;
    margin-left: 91%;
    position: absolute;
  }
  .box {
    width: 418px;
    height: 518px;
    padding: 18px 16px 18px 16px;
    border-radius: 16px;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    background: #ffffff;

    .title {
      display: block;
      margin-bottom: 12px;
      font-size: 22px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #1a1a1a;
    }

    .boxVouchers {
      margin-top: 10px;
      height: 338px;
      overflow-y: auto;
      /* 隐藏滚动条 */
      -ms-overflow-style: none;
      /* IE浏览器特有 */
      &::-webkit-scrollbar {
        display: none;
      }

      .voucher {
        // width: 418px;
        // height: 96px;
        min-height: 96px;
        border-radius: 6px;
        border: 0.5px solid #ff9fa7;
        border-left: 4px solid #ff9fa7;
        margin-bottom: 12px;

        .nominal {
          padding-left: 3px;
          margin-left: 10px;
          margin-top: 10px;
          font-family: JDZhengHT-EN;
          font-size: 24px;
          font-weight: 400;
          color: #CC0C1C;
          line-height: 24px;

          .leftOff {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #CC0C1C;
            margin-left: 5px;
          }
        }

        .line {
          border-top: 1px dashed #ff9fa7; /* 设置虚线 */
          margin: 8px 12px;
        }

        .order {
          margin-top: 5px;
          font-size: 14px;
          margin-left: 10px;
          line-height: 18px;
          text-align: left;
          color: #CC0C1C;
          font-weight: normal;
          transform-origin: 0 center;
        }

        .dayFlex{
          display: flex;
          justify-content: space-between;
          .day {
            margin-top: 4px;
            padding: 2.5px 4px 2.5px 4px;
            border-radius: 4px;
            background-color: #FFF8EA;
            margin-left: 12px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #C99227;
            white-space: nowrap;
            transform-origin: 0 center;
          }
          
        }

        .limitBox {
          display: flex;
          justify-content: space-between;
          
          .limitH16 {
            margin-left: 12px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #888b94;
            white-space: nowrap;
            transform-origin: 0 center;
          }

          .limitH100 {
            margin-left: 12px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 10px;
            text-align: left;
            color: #888b94;
            transform-origin: 0 center;
            overflow: visible; /* 允许溢出 */
            word-break: break-all;
            white-space: normal; /* 多行显示 */
          }

          .expanded {
            cursor: pointer;
            margin-right: 12px;
            color: #888B94;
            transition: color 0.3s ease-in-out;
        
            &:hover {
                color: #FF3F4F;
            }
        
            .svgExpand,
            .svgExpanded {
                cursor: pointer;
                color: inherit;
                margin-left: 10px;
                top: -3px;
                position: relative;
                transition: transform 0.3s ease-in-out; // 添加过渡效果
            }
        
            .svgExpand {
                transform: rotate(0deg);
            }
        
            .svgExpand:hover,
            .svgExpanded:hover {
                color: #FF3F4F;
            }
        
            .svgExpanded {
                transform: rotate(180deg);
            }
          }
        }
      }
    }

    .bottomFloat {
      height: 100px;
      // width: 386px;
      background-color: #FFFFFF;
      // position: absolute;
      // bottom: 0;
    }
    .buttonCheck {
      margin-top: 12px;
      width: 100%;
      height: 50px;
      padding: 16px 0 16px 0;
      border-radius: 8px;
      background: linear-gradient(90deg, #ff475d 0%, #ff0f23 100%);
      font-size: 22px;
      font-weight: 600;
      line-height: 26.25px;
      text-align: center;
      text-transform: none;
      color: #fff;
    }
    .tips {
      margin-top: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: #888B94;
    }
  }
}

.giftFirst{
  background-image: url("https://st.joy-sourcing.com/website/home/gift-box.png"), url("https://st.joy-sourcing.com/website/home/gift-box-bg.png") !important;
  animation: scaleAnimation 1s infinite; /* 设置动画 */
}
@keyframes scaleAnimation {
  0% {
    transform: translate(-50%, -50%) scale(0); /* 从中心缩放到0 */
    opacity: 0; /* 初始透明度 */
  }
  100% {
    transform: translate(-50%, -50%) scale(1); /* 放大到原始大小 */
    opacity: 1; /* 最终透明度 */
  }
}
