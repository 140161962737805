.signinTips {
  height: 32px;
  background-color: #fff8ea;
  .inner {
    width: 1264px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #c99227;
    line-height: 32px;
    span {
      color: #1869f5;
      cursor: pointer;
    }
  }
}
.container {
  width: 1264px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
  .productArea {
    width: 1008px;
    .paginationContainer {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
