.container {
  width: 1008px;
  height: 76px;
  padding: 16px;
  border: 0.5px solid #f0f0f0;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  float: right;
  .progress {
    width: 480px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .bar {
      width: 100%;
      height: 8px;
      background: var(--sys-Surface-Surface300);
      border-radius: 8px;
      position: relative;
      .barIn {
        height: 8px;
        background: var(--sys-color-Primary700);
        border-radius: 8px;
        position: relative;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .op {
    display: flex;
    align-items: center;
    gap: 16px;
    .total {
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 12px;
      .mainNum {
        font-size: 28px;
        line-height: 28px;
      }
    }
    .btn {
      width: 260px;
      height: 44px;
      box-sizing: border-box;
      padding: 8px 36px;
      font-size: 18px;
    }
  }
}
