.checkoutPage {
  background: #F5F6FA;
  padding: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 32px ;

  .errorPage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 375px;
    min-width: 880px;
    border-radius: 8px;
    background-color: #FFFFFF;
    margin: 16px 0;
    position: relative;
  }

  .leftBox {
    width: 880px;
  }

  .rightBox {
    background: #FFFFFF;
    width: 368px;
    padding: 16px;
    border-radius: 8px;
    margin-left: 16px;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}

.leftBox {
  .commonCard {
    padding: 16px;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    &+.commonCard {
      margin-top: 16px;
    }
  }
}

.rightBox {
  font-size: 14px;
  line-height: 18px;
  color: #1A1A1A;
}

.emptyLoading {
  width: 368px;
  height: 390px;
}