.detailWrapper {
  background: #f5f6fa;
  padding: 32px 0;
  display: flex;
  justify-content: center;

  .mainWrapper {
    width: 1264px;
    min-height: 792px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 24px 36px;
    background: #FFF;

    .content_title {
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .versionIconWrap {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #1A1A1A;
        display: flex;
        align-items: center;
        cursor: pointer;
        
        span {
          margin-left: 6px;
        }
      }
    }

    .empty-wrapper {
      flex: 1;
      display: flex;
      justify-content: center;
      margin-left: 16px;
      background: #FFF;
      border-radius: 8px;
    }
  }
}
