.filterArea {
  min-width: 240px;
  max-width: 240px;
  border: 1px solid #e0e1e5;
  border-radius: 8px;
  padding: 12px;
  align-self: flex-start;
  position: sticky;
  top: 0px;
  overflow: auto;
  .header {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    span {
      color: #1a1a1a;
      font-weight: 700;
    }
    .reset {
      cursor: pointer;
      color: #1869f5;
      font-weight: normal;
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1.5px solid #f0f1f2;
    padding-bottom: 12px;
    padding-top: 12px;
    span {
      display: inline-block;
      background: #f7f8fc;
      padding: 5px 12px;
      font-size: 14px;
      border-radius: 4px;
      color: #505259;
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      border: 0.5px solid #f7f8fc;
      &.active {
        border: 0.5px solid #ff0f23;
        background-color: #fff3f4;
        color: #ff0f23;
      }
      &:hover {
        color: #ff3f4f;
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
