.container {
    padding: 16px;
    // align-items: center;
    // justify-content: space-between;
    // justify-items: center;

    .top-select {
        height: 24px;
        display: flex;
        align-items: center;
        position: sticky;
        justify-content: space-between;

        .info-left {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            color: #1A1A1A;
        }

        .info-right {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #888B94;
        }
    }

    .line{
        height: 1px;
        color: #F5F6FA;
    }
    
    .price-container {
        height: 34px;
        // margin-top: 16px;
        // margin-bottom: 16px;
        display: flex;
        align-items: center;
        position: sticky;
        justify-content: space-between;

        .info-left {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #1A1A1A;
        }

        .info-left-bold {
            font-size: 14px;
            font-weight: 700;
            margin-right: 4px;
            line-height: 18px;
            text-align: left;
            color: #1A1A1A;
        }

        .line{
            height: 1px;
            color: #F5F6FA;
        }

        .info-right-bold {
            font-size: 14px;
            font-weight: 700;
            margin-right: 4px;
            line-height: 18px;
            text-align: left;
            color: #CC0C1C;
        }

        .more-right {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #CC0C1C;

            .info-left {
                font-family: JDZhengHT-EN;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-align: right;
                color: #CC0C1C;
            }

            .info-right {
                margin-left: 8px;
                font-family: JDZhengHT-EN;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: right;
                color: #1A1A1A;
            }
        }

        .info-right {
            font-family: JDZhengHT-EN;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: right;
            color: #1A1A1A;
        }

        .info-right-red {
            font-family: JDZhengHT-EN;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: right;
            color: #CC0C1C;
        }

        .icon{
            margin-left: 4px;
            display: flex;
            align-items: center;
            cursor: pointer; 
        }
    }

    .button-check {
        width: 100%;
        height: 44px;
        padding: 10px 16px;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #FFFFFF;
        bottom: 0;
        position: sticky;
        text-transform: none;
        background: #CC0C1C;
    }

    .button-check:disabled {
        background: #FF9FA7;
        color: #FFFFFF;
    }
    .button-check:hover {
        background: #990915;
    }

    .empty-box{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 82px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #C2C4CC;
    }

    .accept {
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #888B94;
    }

    .expanded {
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #1A1A1A;
        transition: color 0.3s ease-in-out;
    
        &:hover {
            color: #990915;
        }
    
        .svg-expand,
        .svg-expanded {
            cursor: pointer;
            color: inherit;
            margin-left: 10px;
            top: -3px;
            position: relative;
            transition: transform 0.3s ease-in-out;
        }
    
        .svg-expand {
            transform: rotate(0deg);
        }
    
        .svg-expand:hover,
        .svg-expanded:hover {
            color: #990915;
        }
    
        .svg-expanded {
            transform: rotate(180deg);
        }
    }    

    .free-shipping {
        margin-top: 16px;
        height: 36px;
        padding: 8px 12px 8px 12px;
        gap: 6px;
        border-radius: 8px;
        background: #EAF8EC;
        align-items: center;
        justify-content: space-between;
        display: flex;
        border: 1px solid #ACE4B4;

        .info {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            text-align: left;
            color: #19191B;
        }

        .spaced {
            word-break: break-all;
            margin-left: 6px; 
        }

        .span {
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            text-align: left;
            color: #269636;
        }

        .go {
            cursor: pointer;
            // width: 34px;
            height: 18px;
            font-size: 14px;
            font-weight: 400;
            display: flex;
            line-height: 18px;
            text-align: left;
            color: #1869F5;
    
            .svg-go {
                color: #888B94;
                margin-left: 7px;
                // width: 2.8px;
                // height: 5.6px;
                position: relative;
                transform: rotate(270deg);
                transition: transform 0.3s ease-in-out; // 添加过渡效果
            }
        }
    }

    .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1264px;
        // background-color: background.paper;
        border: 2px solid #000;
        box-shadow: 24;
    }

    .delete-btn {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #FFFFFFE0;
        // transition: opacity 0.3s ease;
        opacity: 1;
        /* 隐藏删除按钮 */

        .btn-text {
            cursor: pointer;
            // transition: color 0.3s ease;
            text-transform: capitalize;
            width: 41px;
            height: 16px;
            padding: 0px 4px 0px 4px;
            border-radius: 2px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #1A1A1A;
        }

        .btn-text:hover {
            color: #990915;
        }
    }

    .button {
        display: flex;
        .left{
            width: 93px;
            height: 48px;
            padding: 10px 16px 10px 16px;
            gap: 4px;
            border-radius: 4px;
            // font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #FFFFFF;
            text-transform: capitalize;
            background: linear-gradient(90deg, #FF475D 0%, #FF0F23 100%);
        }
        .right{
            margin-left: 8px;
            width: 85px;
            height: 48px;
            padding: 10px 16px 10px 16px;
            gap: 4px;
            border-radius: 4px;
            color: #1A1A1A;
            border: 1px solid #E0E1E5;
            // font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            text-transform: capitalize;
            background: #fff;
        }
    }
}