.reservationBtns {
  position: absolute;
  display: flex;
  gap: 10px;
  right: 336px;
  color: #fff;

  .reservation {
    padding: 0 16px;
    height: 28px;
    line-height: 28px;
    background: #00000033;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }
}