.transactionStepPage {
  width: 1264px;
  margin: 0 auto;
  background-color: #F5F6FA;

  .step-item {
    width: 872px;
    margin: 32px auto;
  }

  .step-label {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #1A1A1A;
  }
}